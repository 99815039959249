.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  flex-shrink: 0;
}

.inputSendBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputContainer {
  position: relative;
  width: 100%;
  flex-shrink: 0;
  // padding-bottom: 50px;
  .input {
    color: #696969;
    width: 100%;
    font-size: 13px;
    border: none;
    padding: 14px;
    padding-left: 20px;
    padding-right: 35px;
    outline-color: #2596be;
    outline-width: 1px;
    border-radius: 25px;
    resize: none;
    outline: 1px solid #a4d1f0;
    -webkit-appearance: none;
  }
  .input::placeholder {
    color: #a3a9ad !important;
  }
  .input::-webkit-scrollbar {
    width: 0;
  }
  .micButton {
    width: 40px;
    height: 40px;
    position: absolute;
    right: -20px;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    .micIcon {
      width: 15px;
    }
  }
  .micButton:hover {
    background-color: transparent;
  }
}

.inputField {
  // color: #696969;
  // width: 100%;
  // font-size: 12px;
  // border: none;
  // border-radius: 5px;
  // // padding: 12px;
  // // padding-left: 20px;
  // // padding-right: 35px;
  // // outline-color: #2596be;
  // border-color: #2596be;
  // border-radius: 20px;
  // resize: none;
  // // outline: 1px solid #a4d1f0;
  .micIcon {
    width: 15px;
    height: auto;
    cursor: pointer;
  }
}

.sendIcon {
  width: 30px;
}

.sendButton {
  width: 40px;
  height: 40px;
  right: -10px;
}
.sendButton:hover {
  background-color: transparent;
}
.inActiveSendBtn {
  width: 40px;
  height: 40px;
  right: -10px;
  opacity: 0.2;
}
.inActiveSendBtn:hover {
  background-color: transparent;
}


.stopRecording {
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  margin-left: -60px;
  margin-right: -60px;
  // margin-bottom: -40px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  height: 0dvh;
  // height: 30dvh;
  background-color: #1B75BA;
  display: flex;
  .stopMicIcon {
    width: 100px;
    display: none;
  }
  .pulse {
    transition: all 0.1s;
    display: none;
    border-radius: 50%;
    opacity: 0.2;
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: 50%;
    right: 50%;
    transform: translate(-50%, -50%);

    width: 85%;
    height: 85%;
  }
  .stopText {
    color: #fff;
    display: none;
  }
  .showChild {
    display: inline;
  }
}

.show {
  height: 25dvh;
}

.disableHover:hover {
  background-color: transparent;
}

/* For WebKit browsers */
textarea::-webkit-scrollbar {
  width: 5px;
}

textarea::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

textarea::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 10px;
}

textarea::-webkit-scrollbar-thumb:hover {
  background: #f1f1f1;
}
