.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  background-color: #ffffff;
  position: relative;

  .container {
    width: 100%;
    height: calc(100vh - 60px);
    overflow: auto;
    position: relative;

    .contentBox {
      width: 100%;
      position: absolute;
      bottom: 0px;
      margin-bottom: 75px;

      .contentCard {
        position: relative;

        .contentBox {
          width: 100%;
          position: absolute;
          bottom: 0px;
          margin-bottom: 75px;

          .contentCard {
            position: relative;
            width: 100%;
            // height: 420px;
            overflow: auto;
            margin-top: 15%;
            // @media only screen and (min-width: 1300px) {
            //     height: 580px;
            //     overflow: auto;
            //     padding-top: 10px;
            // }
            // @media only screen and (min-width: 1285px) and (min-height: 720px) {
            //     height: 480px;
            // }
            @media only screen and (max-width: 1285px) and (max-height: 720px) {
              height: 400px;
            }

            .logoCard {
              text-align: center;
              height: 100px;
              width: 100%;
              @media only screen and (min-width: 1500px) {
                padding-bottom: 10%;
              }
              .logo {
                width: auto;
                height: 30px;
                cursor: pointer;
              }
            }

            .systemChatIcon {
              display: block;
              padding-top: 10px;
              position: relative;

              .titleCard {
                position: relative;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                flex-wrap: wrap;
                gap: 10px;
                padding-bottom: 20px;

                .chatIcon {
                  width: auto;
                  height: 30px;
                }

                .title {
                  font-size: 14px;
                  color: #222222;
                  font-weight: 600;
                  font-family: "Lato", sans-serif;
                  display: flex;
                  align-items: center;
                  gap: 6px;
                }

                .desc {
                  font-weight: 500;
                  color: #222222;
                  font-family: Lato;
                  font-size: 10px;
                  font-style: italic;
                  text-align: center;
                  margin-left: 40px;
                  letter-spacing: 0.2px;
                }
              }
            }

            .destCard {
              width: 100%;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 0px;
              overflow: auto;
              flex-wrap: wrap;

              .destBox {
                // border: 5px solid #7cbeea;
                border-radius: 9px;
                display: inline-block;
                white-space: normal;
                animation: slide-left 10s linear infinite;
              }
            }

            .infoCard {
              display: flex;
              align-items: center;
              justify-content: center;
              padding-top: 20px;
              padding-bottom: 10px;
              z-index: 10;
              .info {
                max-width: 300px;
                & p {
                  text-align: center;
                  font-size: 12px;
                  color: #47174c;
                  font-weight: 500;
                  font-family: "Lato", sans-serif;
                }
              }
            }

            .chatBox {
              margin-left: 16%;
              padding-top: 10px;
              padding-bottom: 10px;
              z-index: 10;

              @media only screen and (min-width: 1300px) {
                margin-left: 18%;
              }
            }
          }
        }

        .logoCard {
          text-align: center;
          height: 100px;
          width: 100%;
          @media only screen and (min-width: 1500px) {
            padding-bottom: 10%;
          }
          .logo {
            width: auto;
            height: 30px;
            cursor: pointer;
          }
        }

        .systemChatIcon {
          display: block;
          padding-top: 10px;
          position: relative;

          .titleCard {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 10px;
            padding-bottom: 20px;

            .chatIcon {
              width: auto;
              height: 30px;
            }

            .title {
              width: 100%;
              font-size: 13px;
              color: #222222;
              font-weight: 600;
              font-family: "Lato", sans-serif;
              display: flex;
              align-items: center;
              gap: 5px;
              max-width: 350px;
            }

            .desc {
              font-weight: 500;
              color: #222222;
              font-family: Lato;
              font-size: 10px;
              font-style: italic;
              text-align: center;
              margin-left: 40px;
              letter-spacing: 0.2px;
            }
          }
        }
        .horizontalScrollContainer {
          // height: 200px;
          // width: 100%;
          // padding-left: 18%;
          // padding-right: 18%;
          position: relative;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          // gap: 0px;
          overflow: auto;
          flex-wrap: wrap;
          .recommendedTripsContainer {
            width: 100%;
            position: relative;
            @media screen and (max-width: 500px) {
              width: 100vw;
            }

            overflow: hidden;

            .scrollContainer {
              position: relative;
              display: flex;
              // align-items: center;
              // justify-content: center;
              overflow: hidden;
              white-space: nowrap;
              margin: 0 5px;
              overflow: auto;
              display: flex;
              gap: 10px;
              // z-index: 0;
              .card {
                display: inline-block;
                white-space: normal;
                animation: slide-left 10s linear infinite;
              }
            }
            .scrollContainer::-webkit-scrollbar {
              width: 0;
              height: 0;
            }

            @keyframes slide-left {
              0% {
                transform: translateX(0%);
              }

              100% {
                transform: translateX(-100%);
              }
            }

            .rightBtnContainer {
              margin-top: 5px;
              cursor: pointer;
              position: absolute;
              right: 0;
              top: 50%;
              bottom: 50%;
              // transform: translateY(-50%);
              background-color: #fff;
              width: 32px;
              height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 8px 0 0 8px;

              .rightBtn {
                width: 32px;
              }
            }

            .leftBtnContainer {
              margin-top: 5px;

              display: flex;
              @media screen and (max-width: 600px) {
                display: block;
              }

              cursor: pointer;
              z-index: 1;
              position: absolute;
              left: 0;
              top: 50%;
              bottom: 50%;
              // transform: translateY(-50%);
              background-color: #fff;
              width: 32px;
              height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 0 8px 8px 0;

              .leftBtn {
                width: 32px;
                rotate: 180deg;
              }
            }
          }
        }

        .destCard {
          width: 100%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0px;
          overflow: auto;
          flex-wrap: wrap;

          .destBox {
            // border: 5px solid #7cbeea;
            border-radius: 9px;
            display: inline-block;
            white-space: normal;
            animation: slide-left 10s linear infinite;
          }
        }

        .infoCard {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 20px;
          padding-bottom: 10px;
          z-index: 10;

          .info {
            max-width: 300px;
            & p {
              text-align: center;
              font-size: 12px;
              color: #47174c;
              font-weight: 500;
              font-family: "Lato", sans-serif;
            }
          }
        }

        .chatBox {
          margin-left: 16%;
          padding-top: 10px;
          padding-bottom: 10px;
          z-index: 10;

          @media only screen and (min-width: 1300px) {
            margin-left: 18%;
          }
        }
      }
    }

    .searchCard {
      width: 100%;
      position: absolute;
      bottom: 0px;

      .testInput {
        height: 100px;
        width: 100%;
        background-color: #ffffff;
      }

      .inputBox {
        padding-top: 10px;
        position: relative;
        display: block;
        padding-left: 6%;
        padding-right: 6%;
        z-index: 2 !important;
      }
    }
  }
}

.main::after,
.main::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: #f2f2f2;
}

::-webkit-scrollbar-thumb {
  background: #b1b1b1;
}

::-webkit-scrollbar-thumb:hover {
  background: #b1b1b1;
}

.constText {
  // width: 92.48%;
  color: #222222;
  font-family: Lato;
  font-size: 12px;
  font-style: italic;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 480px;
  margin-bottom: 20px;
  align-self: center;
  @media only screen and (min-width: 1000px){
    margin-bottom: 0px;
  }
  & h1 {
    padding-left: 40px;
    padding-right: 15px;
    font-size: 13px;
    color: #222222;
    font-style: normal;
    font-family: Lato;
    // margin-left: -15px;
    // margin-right: -30px;
    @media only screen and (min-width: 1000px){
      font-size: 12px;
      padding-left: 20px;
    }
  
  }
  & p {
    padding-left: 40px;
    padding-right: 15px;
    font-size: 12px;
    color: #222222;
    font-style: normal;
    font-family: Lato;
    text-align: center;
    max-width: 380px;
    // margin-left: -15px;
    // margin-right: -30px;
    @media only screen and (min-width: 1000px){
      padding-left: 20px;
    }
  }
}

.infoCard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 10px;
  z-index: 10;
  .info {
    max-width: 300px;
    text-align: center;
    font-size: 12px;
    color: #47174c;
    font-weight: 500;
    font-family: "Lato", sans-serif;
    & p {
      text-align: center;
      font-size: 12px;
      color: #47174c;
      font-weight: 500;
      font-family: "Lato", sans-serif;
    }
  }
}