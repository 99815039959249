.customTabPanelContainer {
  padding: 0 20px;
}

.imageContainer {
  margin: 0 -18px;
  margin-top: 18px;
  margin-bottom: 20px;
  height: 150px;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  @media only screen and (min-width: 767px) {
    margin-left: -10px;
    margin-right: -10px;
  }
  .overlayContainer {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .multiCityIconContainer {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    & img {
      width: 18px;
    }
    & h1 {
      font-size: 11px;
      color: #ffffff;
      font-weight: 600;
    }
  }
  .placeHeadingContainer {
    position: absolute;
    top: 38%;
    bottom: 50%;
    left: 25px;
    & h1 {
      color: #ffffff;
      font-weight: 800;
      font-size: 16px;
    }
    & h2 {
      color: #ffffff;
      font-size: 11px;
      margin-top: 5px;
      text-transform: uppercase;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    aspect-ratio: auto;
    object-fit: cover;
    @media only screen and (min-width: 767px) {
      border-radius: 5px;
    }
  }
}

.tabTextContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 15px;
  @media screen and (min-width: 700px) {
    padding-left: 20px;
  }

  .tabHeading {
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 2px;
    // color: #272727;
  }
  & h1 {
    font-size: 15px;
    font-weight: 800;
  }
  & h2 {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .notSelected {
    color: #c4c4c4;
  }
}

.promptText {
  padding: 10px 10px 32px 10px;
  color: #272727;
  font-family: Lato, sans-serif;
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 17px;

  @media only screen and (min-width: 767px) {
    margin-left: 10px;
    width: 90%;
  }
}

.timeInADay {
  padding: 10px;
  box-sizing: border-box;
  // border: 1px solid #efefef;
  border-radius: 4px;
  background-color: #ffffff;
  // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  .timeInADayHeading {
    color: #272727;
    font-weight: 800;
    padding-bottom: 3px;
  }
}

.title {
  font-weight: 800;
  color: #272727;
}

.description {
  color: #272727;
  font-size: 12px;
}

.budgetEstimatesContainer {
  margin: 0 -20px;
}