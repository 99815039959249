.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    .homeMenuCard {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
      position: absolute;
      top: 25px;
      left: 35px;

      .homeMenuIcon {
        height: 22px;
        width: auto;
        cursor: pointer;
      }
    }

    .navBar {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 60px;
      width: 100vw;
      // background-color: #fff !important;
      // box-shadow:
      //   rgba(27, 31, 35, 0.04) 0px 1px 0px,
      //   rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
      .navBox {
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .menuCard {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 15px;
          // position: absolute;
          // top: 25px;
          // left: 35px;
          padding-left: 35px;

          .menuIcon {
            height: 22px;
            width: auto;
            cursor: pointer;
          }

          .logo {
            margin-top: 2px;
            height: 16px;
            width: auto;
            cursor: pointer;
          }
        }
        .button {
          color: #696969;
          font-family: Lato;
          font-weight: 600;
          font-size: 9px;
          gap: 5px;
          border-radius: 5px;
          width: 120px;
          margin-right: 15px;
          .buttonIcon {
            width: 20px;
            height: 20px;
          }
        }

        .button:disabled {
          background-color: #d5d5d5;
        }
      }
    }

    .sidebar {
      position: absolute;
      top: 0;
      right: 0;
      width: 0px;
      height: 100dvh;
      background-color: #ffff;
      color: black;
      z-index: 99999 !important;
      border-left: 0.2px solid hsl(0, 0%, 0%, 0.2);
      box-shadow:
        rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

      .box {
        position: relative;
        width: 100%;
        height: 100%;

        .loginCard {
          bottom: 20px;
          position: absolute;
          left: 40%;

          @media only screen and (max-width: 767px) {
            left: 40%;
          }
        }
      }
    }

    .sidebarOpen {
      left: 0;
      width: 400px;
      transition: 0.3s !important;

      @media only screen and (max-width: 767px) {
        width: calc(100vw - 10vw);
      }

      @media only screen and (max-width: 600px) {
        width: calc(100vw - 10vw);
        transition: width 0.1s ease-in-out;
        animation: slideIn 0.3s linear forwards;
      }
    }

    .sideBarClose {
      left: -400px;
      width: 0px;
      opacity: 0;
      transition: 0.6s !important;
    }

    @keyframes slideIn {
      0% {
        transform: translateX(-100%);
      }

      100% {
        transform: translateX(0);
      }
    }

    // &.open .sidebar {
    //     animation: smoothScroll 0.5s forwards;
    // }
  }
}
