.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  padding-top: 5px;
  @media only screen and (min-width: 767px) {
    padding-top: 10px;
  }
  .editIcon {
    width: auto;
    height: 20px;
    cursor: pointer;
    @media only screen and (min-width: 767px) {
      height: 15px;
    }
  }

  .editText {
    margin-top: 1px;
    font-size: 10px;
    color: #696969;
    font-family: "Lato";
    font-weight: 600;
    cursor: pointer;
    letter-spacing: 0px;
    @media only screen and (min-width: 767px) {
      font-size: 8px;
    }
  }
}

.main::after,
.main::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
}
