.mainContainer {
  height: 160px;
  // overflow: hidden;
  position: relative;
  overflow: hidden;
  display: flex;
  border-radius: 5px;
  //   width: calc(100vw - 10px);
  width: 100%;
  // width: 200px;
  //   margin: 0 -25px;
  @media screen and (min-width: 700px) {
    margin-bottom: 20px;
  }
  .imageContainer {
    height: 160px;
    width: 100%;
    display: flex;
    .img {
      height: 160px;
      // aspect-ratio: 1 / 1.08;
      object-fit: cover;
      // flex-shrink: 0;
    }
  }
}
.placeHeadingContainerSelected {
  height: 100%;
}
.placeHeadingContainer {
  min-height: 35px;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 15px;
  .textContainer {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
    .cityNameContainer {
      display: flex;
    }
    .cityNameContainerSelected {
      position: absolute;
      top: 40%;
      bottom: 50%;
    }
    .nextIcon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5px;
      width: 15px;
      z-index: 1;
    }
  }
  .placeHeadingMobContainer{
    display: flex;
    flex-direction: column;
    & h2{
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
  .placeHeadingMob {
    color: #ffffff;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    display: flex;
    // @media screen and (max-width: 550px) {
    //   display: flex;
    // }
    z-index: 1;
  }
  .placeHeadingDesk {
    z-index: 1;
    color: #ffffff;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    display: flex;
    @media screen and (max-width: 550px) {
      display: none;
    }
  }

  .priceText {
    z-index: 1;
    flex-shrink: 0;
    color: #f4f6f8;
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.32px;
    line-height: 18px;
    text-align: center;
    height: 20px;
  }
  .priceTextSelected {
    position: absolute;
    bottom: 5px;
  }
}

.selectedIcon {
  z-index: 2;
  height: 40px;
  position: absolute;
  bottom: 0px;
  right: 5px;
}
