@use "./variables/color" as *;

* {
  padding: 0;
  font-family: "Lato", sans-serif;
  margin: 0;
  box-sizing: border-box;
}
html {
  overflow-x: hidden;
}

h1 {
  color: $font-color;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

h2 {
  font-family: "Lato", sans-serif;
  color: $font-color;
  font-size: 12px;
  font-weight: 400;
}

h3 {
  font-family: "Lato", sans-serif;
  color: $font-color;
  font-size: 12px;
  font-weight: 600;
}

h4 {
  font-family: "Lato", sans-serif;
  color: $font-color;
  font-size: 16px;
  font-weight: 700;
}

h5 {
  font-family: "Lato", sans-serif;
  font-weight: 700;
}
h6 {
  font-family: "Lato", sans-serif;
  color: $font-color;
  font-size: 12px;
  font-weight: 400;
}

input::placeholder {
  font-family: "Lato", sans-serif;
}

input {
  font-family: "Lato", sans-serif;
}

label {
  font-family: "Lato", sans-serif;
}

.test {
  color: #9c9c9c;
}


/** Swipe to delete **/
.rstdi {
  --rstdiHeight: 30px;
  --rstdiTransitionDuration: 250ms;
  --rstdiTranslate: 0px;
  --rstdiIsRtl: 0;
  --rstdiDeleteColor: rgba(252, 58, 48, 1);
  --rstdiDeleteWidth: 75px;
  --rstdiButtonMarginRight: 0px;
  --rstdiButtonMarginLeft: 0px;

  width: auto;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  height: var(--rstdiHeight);
  max-height: calc(var(--rstdiHeight) + 10px);
}

.rstdi *,
.rstdi *:before,
.rstdi *:after {
  box-sizing: border-box;
}

.rstdi.deleting {
  transition: all var(--rstdiTransitionDuration) ease-out;
  max-height: 0;
}

.rstdi .content {
  height: 100%;
  width: auto;
  position: relative;
  transform: translateX(var(--rstdiTranslate));
}

.rstdi .content.transition {
  transition: transform var(--rstdiTransitionDuration) ease-out;
}

.rstdi .content.deleting {
  height: 100%;
  width: auto;
  position: relative;
  transform: scale(0) translateX(var(--rstdiTranslate));
}

.rstdi .delete {
  position: absolute;
  right: 10px;
  left: 10px;
  height: 100%;
  top: 6px;
  // background-color: var(--rstdiDeleteColor);
  height: 58px;
  font-weight: 400;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0 5px 5px 0;
}

.rstdi .delete button {
  width: var(--rstdiDeleteWidth);
  transition: margin transform var(--rstdiTransitionDuration) ease-in-out;
  margin-left: var(--rstdiButtonMarginLeft);
  margin-right: var(--rstdiButtonMarginRight);
  text-align: center;
  height: 100%;
  background: transparent;
  border: none;
  color: white;
  font-size: 14px;
  padding-right: 30px;
}