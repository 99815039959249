.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 30px;
  padding-bottom: 50px;
  padding-top: 30px;
  .headingText {
    margin-left: 3px;
    color: #696969;
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.34px;
    line-height: 19px;
  }
  .headingText2 {
    margin-left: 3px;
    color: #696969;
    font-family: Lato;
    font-size: 10px;
    letter-spacing: 0.34px;
    line-height: 19px;
    margin-top: -12px;
  }
}

.componentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  .leftContainer {
    display: flex;
    gap: 10px;
    align-items: center;
    .image {
      width: 42px;
      margin-right: 5px;
    }
    .checkboxIcon{
      width: 19px;
    }
    .title {
      color: #696969;
      font-family: Lato;
      font-size: 14px;
      letter-spacing: 0.3px;
      line-height: 17px;
    }
  }
  .rightContainerText {
    color: #696969;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
    font-weight: 600;
  }
}
